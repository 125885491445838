<template>
<transition :duration="5500">
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>
      <b-button left v-b-toggle.collapse-1 variant="outline-secondary" size="sm">Find...
          <b-icon-search> aria-label="Search"></b-icon-search>
      </b-button>
      {{">>" + JSON.stringify(filterParams()).replace(/{|}|"/g, "").replace(/,/g,", ")}}
    </b-row>
    <b-collapse id="collapse-1" class="mt-2">
    <b-card bg-variant="light">
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Sort"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>

              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
<!-- 
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Initial sort"
            label-for="initial-sort-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="sortDirection"
              :options="['asc', 'desc', 'last']"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col> -->

        <!-- <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col> -->
        <b-col lg="6" class="my-1" v-for="(item,index) in filter_list" :key="index">
          <b-form-group v-if="item.type === 'string'"
            :label="item.label"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="item.model"
                type="search"
                placeholder="String Search"
              ></b-form-input>

              <!-- <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append> -->
            </b-input-group>
          </b-form-group>

          <b-form-group v-if="item.type === 'number-list'"
            :label="item.label"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="item.model"
                type="search"
                placeholder="Number Search"
              ></b-form-input>

              <!-- <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append> -->
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- <b-col lg="6" class="my-1">
          <b-form-group
            v-model="sortDirection"
            label="Filter On"
            description="Leave all unchecked to filter on all data"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
              <b-form-checkbox 
                v-for="item in filterOptions" 
                :key="item.value"
                :value="item.value"
              >
                {{item.text}}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col> -->
      </b-row>
          <b-row>
      <b-col>
        <b-button block variant="primary" @click="callApi">Find</b-button>
      </b-col>
    </b-row>
    </b-card>
    </b-collapse>

    <b-row>
      <b-col b-col md="4" class="my-1">
        Total {{totalRows}}
      </b-col>
      <b-col b-col md="4" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="table-transition-example"
          pills
          align="fill"
          class="my-1"
          size="sm"
        ></b-pagination>
      </b-col>
      <b-col md="4" class="my-1">
        <!-- <b-button variant="outline-primary" size="sm" :href="link" >Add Entry</b-button> -->
        <b-button variant="outline-primary"
          size="sm" @click="addEntry"
          v-b-tooltip.hover.right title="Add Entry"
        >
          <b-icon-plus-circle-fill> aria-label="Add Entry"></b-icon-plus-circle-fill>
        </b-button>
      </b-col>
    </b-row>

    <b-table
      id="table-transition-example"
      :tbody-transition-props="transProps"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
      <template #cell(ams-type)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
          <b-link :to="{ path: '/' }">{{data.value}}</b-link>
      </template>

      <template #cell(actions)="row">
        <b-dropdown idropleft squared variant="outline-secondary" size="sm" >
          <template #button-content>
            <b-icon-option aria-hidden="true"></b-icon-option>
            options
          </template>
          <b-dropdown-item-button size="sm" @click="show_item(row.item)" class="mr-1">
            Show
          </b-dropdown-item-button>
          <b-dropdown-item-button size="sm" @click="update_item(row.item)" class="mr-1">
            Update
          </b-dropdown-item-button>
          <b-dropdown-item-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
            <b-icon-triangle aria-hidden="true"></b-icon-triangle>
            Info modal
          </b-dropdown-item-button>
          <b-dropdown-item-button size="sm" @click="row.toggleDetails">
            <b-icon-text-paragraph aria-hidden="true"></b-icon-text-paragraph>
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-dropdown-item-button>
          <b-dropdown-item-button variant="danger" @click="destroy_item(row.item)">
            <b-icon-trash-fill aria-hidden="true"></b-icon-trash-fill>
            Delete
          </b-dropdown-item-button>
        </b-dropdown>
      </template>

      <template #row-details="row">
        <ams-relationships-card :node="row.item" :lookup="included"/>
      </template>
    </b-table>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
  </b-container>
</transition>
</template>

<script>
import { BIconOption, BIconTextParagraph, BIconTriangle, BIconTrashFill, BIconPlusCircleFill, BIconSearch } from 'bootstrap-vue'
import {http_v1} from '@/axios'
import {amsSchema} from '@/schema'
import AmsRelationshipsCard from '@/components/AmsRelationshipsCard.vue'

export default {
  components: { 
    AmsRelationshipsCard, BIconOption, BIconTextParagraph, BIconTriangle, BIconTrashFill, BIconPlusCircleFill, BIconSearch
  },
  props: {
    ams_type: {
      Type: String
    },
    ams_api_path: {
      Type: String
    },
    ams_fields: {
      Type: Array
    },
    ams_template: {
      Type: Object
    },
    reroute_name: {
      Type: String
    },
    describe_belongs_to: {
      Type: Array
    },
    filtering: {
      Type: Object
    },
    refresh: {
      Type: Boolean
    },
    per_page_setting: {
      Type: Number
    }
  },
  data() {
    return {
      link: "/" + this.ams_type + "/create",
      api_route: this.ams_api_path.replace(/-/g, '_') + '/' + this.ams_type.replace(/-/g, '_') + '/',
      included: [],
      items: [],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      fields: [
        {
          // A column that needs custom formatting,
          // calling formatter 'fullName' in this app
          key: 'id',
          label: 'Belongs to',
          formatter: 'belongDescription',
          class: 'text-left',

        },
        ...this.ams_fields,
        { key: 'actions', label: 'Actions' }
      ],
      totalRows: undefined,
      currentPage: 1,
      perPage: 5,
      sortBy: '',
      sortDesc: false,
      // sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      filter_list: []
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter(f => f.sortable).map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  mounted() {
    // if(this.ams_template?.attributes) {
    //   this.attr_copy = {...this.ams_template.attributes} // Don't need attr_copy
    // }
    // else {
    //   const included_obj = amsSchema.find( 
    //     el =>  
    //     el.type === this.ams_type
    //   )
    //   this.attr_copy = {...included_obj.attributes} // Don't need attr_copy
    // }
    const included_obj = amsSchema.find( 
      el =>  
      el.type === this.ams_type
    )
    this.filter_list = included_obj.filters ? included_obj.filters : []
    this.callApi()
  },
  methods: {
    addEntry() {
      if(this.reroute_name) {
        this.$router.push( {
          name: this.reroute_name,
          params: {
            ams_template: this.ams_template
          }
        })
      }
      else {
        this.$router.push( {
          path: this.link
        })
      }
    },
    belongDescription(id) {
      var that = this
      function describe_relationship(obj) {
        let description = ''

        let included_obj = that.included.find( 
          el =>  
          el.type === obj.data.type && el.id === obj.data.id
        )
        let has_description = that.describe_belongs_to.find(
          el =>
          el.type === obj.data.type
        )
        if (has_description ) {
          //  describe_belongs_to: [
          //   {
          //     prepend: '#',
          //     type: 'brands',
          //     description_attr: 'attributes.name',
          //     append: ': '
          //   }
          // ]
          // Handle interger and string types using Template Literals `${a}`
          description +=  [
            has_description.prepend,
            `${included_obj.attributes[has_description.description_attr]}`,
            has_description.append
          ].filter(Boolean).join("")
          // filter(Boolean) to remove any falsies, undefined, nulls, etc.
        }
        let relationship_description = ''
        if (included_obj?.relationships) {
          Object.values(included_obj.relationships).forEach(relationship => 
            relationship_description = describe_relationship(relationship)
          )
          description = relationship_description + description
        }
        return description
      }
      let belong_description = ""
      if( this.describe_belongs_to) {
        let item = this.items.find(el => el.id === id)
        Object.values(item.relationships).forEach(relationship => 
            belong_description += describe_relationship(relationship)
        )
      }
      return belong_description
    },
    callApi () {
      let sorting = null
      if(this.sortBy.length) {
        sorting = this.sortDesc ? '-' : '+'
        sorting += this.sortBy.substring(11).replace(/-/g, '_') // 'attriubutes. offset substring
      }
      http_v1.get (
        this.api_route, {
          params: {
            page: this.currentPage,
            per_page: this.per_page_setting,
            sort: sorting,
            ... this.filtering,
            ... this.filterParams()
          }
        }
      ).then(response => {
        if(response.status == 200) {
          this.totalRows = response.headers.total
          this.perPage = response.headers['per-page']
          this.items = response.data.data
          this.included = response.data.included
        }
        else {
          window.console.log(response.status)
        }
      }).catch(error => {
        this.items = []
        this.totalRows = 0
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          window.console.log(message)
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    show_item(item) {
      this.$router.push( {path: '/' + this.ams_type + '/' + item.id + '/show'})
    },
    update_item(item) {
      this.$router.push( {path: '/' + this.ams_type + '/' + item.id + '/update'})
    },
    destroy_item(item) {
      http_v1.delete (
        this.api_route + item.id, {
        }
      ).then(response => {
        if( response.status == 204) {
          var message = response.data.message || JSON.stringify(response.data)
          this.$bvToast.toast(message, {
            title: 'Deleted',
            autoHideDelay: 5000,
            appendToast: true
          })
          this.callApi()
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Set Filter params and call API, resetting page to beginning
      window.console.log(filteredItems)
      this.currentPage = 1
      this.callApi()
    },
    filterParams() {
      const obj = {}  // Consider Reduce operation
      let param_list = this.filter_list.filter(f => f.model)
      param_list.forEach(param => {
        const key = param.label
        obj[key] = param.model
      })
      return obj
    },
  },
  watch: {
    refresh(val) {
      if(val === true) {
        this.currentPage = 1
        this.callApi()
        this.$emit('refreshed', val)
      }
    },
    currentPage() {
      this.callApi()
    }
  }
}
</script>

<style scoped>
table#table-transition-example .flip-list-move {
  transition: transform 1s;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
