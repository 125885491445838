export const amsSchema = [
  {
    id: '',
    type: 'collections',
    attributes: {
      'name': '',
      'max-sum-bytesize' : 10485760
    },
    filters: [
      {type: 'string', label: 'name', model: ''}
    ]
  },
  {
    id: '',
    type: 'brands',
    attributes: {
      'name': ''
    },
    filters: [
      {type: 'string', label: 'name', model: ''}
    ]
  },
  {
    id: '',
    type: 'product-models',
    attributes: {
      'brand-id': '',
      'name': '',
      'description': ''
    },
    filters: [
      {type: 'string', label: 'name', model: ''},
      {type: 'string', label: 'description', model: ''}
    ]
  },
  {
    id: '',
    type: 'product-listings',
    attributes: {
      'product-model-id': '',
      'edition': 0,
      'price-cents': 0,
      'description': ''
    },
    filters: [
      {type: 'number-list', label: 'editions', model: undefined},
      {type: 'string', label: 'description', model: ''}
    ]
  },
  {
    id: '',
    type: 'product-items',
    attributes: {
      'collection-id': '',
      'product-listing-id': '',
      'description': '',
      'serial-number': 0,
      'purchased-price-cents': 0,
      'estimated-min-price-cents': 0,
      'estimated-max-price-cents': 0,
      'authenticated-min-price-cents': 0,
      'authenticated-max-price-cents': 0,
      'asking-price-cents': 0,
      'sold-price-cents': 0,
      'estimated-quality': 0,
      'authenticated_quality': 0,
      'at-status': 0,
      'collected-at': '',
      'prepared-at': '',
      'delivered-at': '',
      'received-at': ''
    },
    filters: [
      {type: 'number-list', label: 'serial_numbers', model: undefined}
    ]
  },
  {
    id: '',
    type: 'actor-roles',
    attributes: {
      'work-group-id': '',
      'profile-id': '',
      'role': 'manager',
      'can-read': true,
      'can-create': true,
      'can-update': true,
      'can-destroy': false
    },
    filters: [
      {type: 'enumerated-list', label: 'roles', model: undefined}
    ]
  },
  {
    id: '',
    type: 'work-groups',
    attributes: {
      'collection-id': '',
      'name': ''
    },
    filters: [
      {
        type: 'string',
        label: 'names',
        model: ''
      }
    ]
  },
  {
    id: '',
    type: 'profiles',
    attributes: {
      'user_id': '', 
      'nickname': '',
      'unique-alias': '',
      'mobile': '',
      'location': '',
      'will-work': false,
      'will-audit': false,
      'will-consume': true
    },
    filters: [
      {type: 'string', label: 'text', model: ''}
    ]
  },
  {
    id: '',
    type: 'users',
    attributes: {
      'name': '',
      'password': '',
      'password-confirmation': '',
      'is-admin': false
    },
    filters: [
      {type: 'string', label: 'text', model: ''}
    ]
  }
]