<template>
<b-card bg-variant="light">
  <b-card-group deck id="app">
    <ams-card :json_ams="node" :show_show="true" />
    <b-card-group deck v-if="node.relationships">
      <node v-for="(value,key) in node.relationships" 
        :node="lookup_child(value)" 
        :lookup="lookup" 
        :key="key"
      />
    </b-card-group>
  </b-card-group>
</b-card>
</template>

<script>
import AmsCard from '@/components/AmsCard.vue'

export default {
  components: { AmsCard },
  name: "node",
  props: {
    node: Object,
    lookup: Array
  },
  methods: {
    lookup_child (child) {
      return this.lookup.find( 
        el =>  
        child.data && el.type === child.data.type && el.id === child.data.id
      ) || {}
    } 
  }
}
</script>