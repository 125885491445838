<template>
  <b-card
    border-variant="info"
    align="center"
    :header=json_ams.type
    header-bg-variant="secondary"
    header-text-variant="white"
  >
    <b-card-text align="left" v-for="(item, key) in filteredAttributes" :key="key">
      <em>{{key}}:</em> <b>{{item}}</b>
    </b-card-text>
    <b-card-footer v-if="show_show || show_update">
      <!-- <b-row>
        <b-col v-if="show_show">
          <a :href="showLink" class="card-link">Show</a>
        </b-col>
        <b-col v-if="show_update">
          <a :href="updateLink" class="card-link">Update</a>
        </b-col>
      </b-row> -->
        <b-row>
          <b-col>
            <b-button-group>
              <b-button size="sm"
                class="mb-2"
                v-b-tooltip.hover.top title="Go Update"
                @click="goUpdate"
              >
                <b-icon-triangle aria-label="Update"></b-icon-triangle>
              </b-button>
              <b-button size="sm"
                class="mb-2"
                v-b-tooltip.hover.down title="Go View"
                @click="goShow"
              >
                <b-icon-eye-fill aria-label="View"></b-icon-eye-fill>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BIconEyeFill,
  BIconTriangle,
} from 'bootstrap-vue'
export default {
  components: {
    BIconEyeFill,
    BIconTriangle
  },
  props: {
    json_ams: {
      Type: Object
    },
    show_show: {
      Type: Boolean
    },
    show_update: {
      Type: Boolean
    }
  },
  computed: {
    filteredAttributes() {
      if( this.json_ams.attributes) {
        return Object.keys(this.json_ams.attributes).filter(
          (key) => key !== 'created-at' && key !== 'updated-at' && key !== 'id'  && ! key.endsWith('-id')
          ).reduce(
            (cur, key) => { return Object.assign(cur, { [key]: this.json_ams.attributes[key] })}, {}
          )
      }
      else {
        return []
      }
    },
    // updateLink() {
    //   return "/" + this.json_ams.type + "/" + this.json_ams.id + "/update"
    // },
    // showLink() {
    //   return "/" + this.json_ams.type + "/" + this.json_ams.id + "/show"
    // },
  },
  methods: {
    goShow() {
      this.$router.push( {path: '/' + this.json_ams.type + '/' + this.json_ams.id + '/show'})
    },
    goUpdate() {
      this.$router.push( {path: '/' + this.json_ams.type + '/' + this.json_ams.id + '/update'})
    }
  }
}
</script>